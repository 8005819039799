<template>
  <v-form
    ref="testform"
    v-model="valid"
    @submit.prevent="onSubmit"
  >
    <v-card
      height="100%"
      flat
    >
      <v-card-title>
        <slot name="form-title">
          <span class="font-weight-semibold text-base text--primary">{{ $t("Add a Test") }}</span>
          <v-spacer />
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="$emit('update:is-form-active',false)"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </slot>
      </v-card-title>

      <v-card-text
        class="overflow-y-auto"
        style="max-height: 600px;"
      >
        <v-row class="pt-5">
          <v-col
            cols="12"
          >
            <translatable-text-field
              v-model="item.name"
              outlined
              dense
              type="text"
              :error-messages="errorMessages.name"
              :label="$t('Name')"
              :placeholder="$t('Name')"
              hide-details="auto"
            ></translatable-text-field>
          </v-col>
          <v-col
            cols="12"
          >
            <translatable-textarea
              v-model="item.description"
              :label="$t('Decription')"
              :placeholder="$t('Description')"
              outlined
              dense
              hide-details="auto"
              clearable
              rows="1"
            >
            </translatable-textarea>
          </v-col>

          <v-col
            cols="12"
            class="tible"
          >
            <h2 class="subtitle-2">
              {{ $t("Results") }}
            </h2>
          </v-col>
          <v-col cols="12">
            <v-row
              v-for="(result, index) in item.results"
              :key="index"
            >
              <v-col
                cols="12"
                md="5"
              >
                <translatable-text-field
                  v-model="result.name"
                  outlined
                  dense
                  type="text"
                  :error-messages="errorMessages.name"
                  :label="$t('Name')"
                  :placeholder="$t('Name')"
                  hide-details="auto"
                ></translatable-text-field>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <translatable-text-field
                  v-model="result.description"
                  :label="$t('Decription')"
                  :placeholder="$t('Description')"
                  outlined
                  dense
                  row
                  hide-details="auto"
                  clearable
                >
                </translatable-text-field>
              </v-col>
              <v-col
                cols="12"
                md="1"
              >
                <v-icon
                  color="danger"
                  @click.stop="removeResult(index, result)"
                >
                  {{ icons.mdiClose }}
                </v-icon>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <slot name="form-actions">
          <slot name="form-cancel">
            <v-btn
              :loading="loading"
              color="secondary"
              outlined
              type="reset"
              @click="isFormActive = false"
            >
              {{ $t('Cancel') }}
            </v-btn>
          </slot>
          <v-spacer />

          <v-btn
            :loading="loading"
            color="info"
            class="me-3"
            @click="addResult"
          >
            {{ $t("Add result") }}
          </v-btn>
          <v-btn
            :loading="loading"
            color="primary"
            class="me-3"
            type="submit"
          >
            {{ $t("Save") }}
          </v-btn>
        </slot>
      </v-card-actions>
    </v-card>
    <v-snackbar
      v-model="isSnackbarBottomVisible"
      :color="$vuetify.theme.currentTheme.error"
      dark
    >
      {{ snackBarMessage }}
    </v-snackbar>
  </v-form>
</template>

<script>
import {
  mdiClose,
  mdiPlus,
  mdiPencilOutline,
  mdiPencilOffOutline,
  mdiCalendar,
  mdiCloudUploadOutline,
} from '@mdi/js'
import {
  getIndex,
  getItem,
} from '@core/utils'
import {
  computed,
  ref,
  watch,
} from '@vue/composition-api'
import {
  required,
  emailValidator,
} from '@core/utils/validation'
import axios from '@axios'
import {
  format,
} from 'date-fns'
import {
  avatarText,
} from '@core/utils/filter'
import store from '@/store'
import controller from './Controller'

import AvatarName from '@/components/AvatarName'
import UserForm from '@/views/apps/user/user-list/UserForm'

import FileUpload from '@/components/file_upload'
import TranslatableTextField from '@/components/TranslatableTextField.vue'
import TranslatableTextarea from '@/components/TranslatableTextarea.vue'

export default {
  components: {
    TranslatableTextarea,
    TranslatableTextField,
    FileUpload,
    UserForm,
    AvatarName,
  },
  model: {
    prop: 'object',
    event: 'update:is-form-active',
  },
  props: {
    object: {
      type: Object,
      default: () => {},
    },
    isFormActive: {
      type: Boolean,
      required: true,
    },
    withPassword: {
      type: Boolean,
      default: true,
    },
    roleOptions: {
      type: Array,
      default: () => [],
    },
    planOptions: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, {
    emit,
  }) {
    const {
      form,
      isSnackbarBottomVisible,
      snackBarMessage,
    } = controller()

    const errorMessages = ref({})
    const valid = ref(false)
    const isFormuserActive = ref(false)
    const loading = ref(false)
    const users = ref([])
    const isLoadingusers = ref(false)
    const search = ref(null)
    const minDate = new Date().toISOString().substr(0, 10)
    const validate = () => {
      form.value.validate()
    }

    const item = computed({
      get: () => props.object,
      set: value => emit('update:object', value),
    })

    if (!item.value.id) item.value = {name: {}, description: {}, results: []}
    if (item.value.user && users.value.findIndex(ele => ele.id === item.value.user_id) < 0) {
      users.value.push(item.value.user)
    }
    watch(item, val => {
      if (item.value.user && users.value.findIndex(ele => ele.id === item.user_id) < 0) {
        users.value.push(item.value.user)
      }
    }, {
      deep: true,
    })

    // const item = ref(JSON.parse(JSON.stringify(blankItem)))
    const resetItem = () => {
      // item.value = {}

      // emit('update:object', item.value)
    }
    const removeResult = (index, result) => {
      item.value.results.splice(index, 1)
      if (result.id != null) {
        axios.delete(`/results/${result.id}`)
      }
    }
    const addResult = () => {
      item.value.results = item.value.results || []
      item.value.results.push({})
    }
    const formatDate = (date, f) => format(date, f)
    const newuser = () => {
      item.value.user = {
        name: search.value, email: null, tell: null,
      }
      isFormuserActive.value = true
    }
    const searchusers = term => {
      store.dispatch('app/searchUsers', { term, rowsPerPage: 100 }).then(response => {
        isLoadingusers.value = false
        console.log('users', response.data.data)
        for (let i = 0; i < response.data.data.length; i++) {
          const u = response.data.data[i]
          const indexU = users.value.findIndex(el => el.id === u.id)
          if (indexU < 0) {
            users.value.push(u)
          }
        }
      })
    }
    const updateusers = user => {
      console.log(user)
      isFormuserActive.value = false
      users.value.push(user)
      item.value.user = user
      item.value.user_id = user.id
    }
    const onSubmit = () => {
      if (valid.value) {
        loading.value = true
        store.dispatch('app-test/addItem', item.value)
          .then(response => {
            loading.value = false
            if (response.data.error || response.data.errors || (response.data.status && response.data.status === 'false')) {
              if (response.data.errors) {
                // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
                errorMessages.value = {
                  ...response.data.errors,
                }
              } else {
                isSnackbarBottomVisible.value = true
                snackBarMessage.value = response.data.message

                // this.toast(response.data.message, this.$vuetify.theme.currentTheme.error)
              }

              return true
            }
            resetItem()

            // emit('update:is-form-active', false)

            emit('refetch-data')
          }).catch(error => {
            loading.value = false
            console.log('error', error)
            if (error.data.errors) {
              // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
              errorMessages.value = {
                ...error.data.errors,
              }
            }
          })
      } else {
        validate()
      }
    }

    searchusers('')

    // onMounted(searchParticular)
    watch(search, val => {
      if (!val || val.trim().length < 3 || isLoadingusers.value) return ''
      isLoadingusers.value = true
      searchusers(val)

      return ''
    })

    const userIdChanged = computed({
      get: () => item.user_id,
    })

    watch(userIdChanged, val => {
      item.value.country_id = users.value.find(i => i.id === val).country_id

      return ''
    })

    // onMounted(searchParticular)

    return {
      minDate,
      resetItem,
      form,
      isFormuserActive,
      search,
      users,
      updateusers,
      newuser,
      isSnackbarBottomVisible,
      snackBarMessage,
      errorMessages,
      onSubmit,

      item,
      valid,
      loading,
      validate,
      avatarText,
      addResult,
      removeResult,
      // validation
      validators: {
        required,
        emailValidator,
      },
      icons: {
        mdiClose,
        mdiPencilOffOutline,
        mdiPencilOutline,
        mdiCalendar,
        mdiPlus,
        mdiCloudUploadOutline,
      },
    }
  },
}
</script>
