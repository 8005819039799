<template>
  <div id="order-list">
    <!-- app drawer -->

    <v-dialog
      v-model="isFormActive"
      touchless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.smAndUp ? 700 : '100%'"
    >
      <i-form
        v-model="item"
        :is-form-active="isFormActive"
        :headers="filters.filter(ele => ele.fields )"
        :config="{url: '/orders' }"
        @refetch-data="fetchItems"
        @cancel="isFormActive = false"
      >
        <template v-slot:form-title>
          <span class="font-weight-semibold text-base text--primary">{{ item.id ? $t('Update'): $t('Add order') }}</span>
          <v-spacer />
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="isFormActive = !isFormActive"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </template>
        <template v-slot:form-cancel>
          <v-btn
            color="secondary"
            outlined
            type="reset"
            @click="isFormActive = !isFormActive"
          >
            {{ $t('Cancel') }}
          </v-btn>
        </template>
      </i-form>
    </v-dialog>

    <!-- ======================TEST FORM====================== -->
    <v-dialog
      v-model="isProductsFormActive"
      touchless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.smAndUp ? 400 : '100%'"
    >
      <products
        v-model="item"
        object="item"
        :is-form-active="isProductsFormActive"
        :pay-options="payOptions"
        :plan-options="planOptions"
        @refetch-data="fetchItems"
      >
        <template v-slot:form-title>
          <div class="d-flex align-center">
            <v-avatar
              v-if="item.user && item.user.image"
              :color="item.user && item.user.image ? '' : 'primary'"
              :class="item.image ? '' : 'v-avatar-light-bg primary--text'"
              size="30"
            >
              <v-img
                v-if="item.user && item.user.image"
                :lazy-src="require(`@/assets/images/avatars/2.png`)"
                :src="item.image || require(`@/assets/images/avatars/2.png`)"
              ></v-img>
              <span
                v-else
                class="font-weight-medium"
              >{{ avatarText(item.user && item.user.name) }}</span>
            </v-avatar>

            <div
              v-if="item.id"
              class="d-flex flex-column ms-3"
            >
              <router-link
                :to="{ name : 'user-view', params : { id : item.user_id } }"
                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{$t("Order")}} #{{ item.id }}
              </router-link>
              <!--              <small>@{{ item.manuscriptname }}</small>-->
            </div>
          </div> -
          <span class="font-weight-semibold text-base text--primary">"{{ $t('Products')}}"</span>
          <v-spacer />
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="isProductsFormActive = !isProductsFormActive"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </template>
        <template #form-actions>
          <v-btn
            :disabled="item.status > 3"
            :loading="loading"
            color="info"
            class="me-3"
            @click="validateOrder(item)"
          >
            {{ $t("Validate") }}
          </v-btn>
        </template>
      </products>

      <!-- <v-card-actions>
        <slot name="form-actions">
          <v-btn
            v-if="$can('edit','Order')"
            color="primary"
            class=" me-3"
            @click.stop="openProductsDialog(false)"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>{{ $t("Add  Test") }}</span>
          </v-btn>
        </slot>
        <v-spacer />
        <v-btn
          :loading="loading"
          color="primary"
          class="me-3"
          type="submit"
        >
          {{ $t("Save") }}
        </v-btn>
      </v-card-actions> -->
    </v-dialog>

    <!-- list filters -->
    <v-card>
      <v-card-title>
        {{ $t("Orders") }}<v-chip
          outlined
          class="ml-2"
        >
          {{ totalItems }}
        </v-chip>
        <v-spacer />
        <!--        <export-excel
          :options="downloadObject"
          @refetch-data="fetchItems"
        />-->
      </v-card-title>

      <!-- table -->
      <v-data-table
        v-model="selectedRows"
        class="fixed-table rides-table"
        :headers="filters"
        :items="items"
        :options.sync="options"
        :page.sync="page"
        :server-items-length="totalItems"
        :loading="loading"
        :loading-text="$t('Loading data')"
        :no-data-text="$t('No data')"
        :no-results-text="$t('No result')"
        hide-default-footer
        :show-select="true"
        dense
        fixed-header
        :height="tableHeight"
        @page-count="pageCount = $event"
      >
        <template #body.prepend="{ items, headers }">
          <header-filters
            :headers="filters"
            :show-select="true"
          />
        </template>
        <template #item="options">
          <Row
            :options="options"
            :show-select="true"
            completed-field="publication.state"
            :config="{ url: '/orders/action/', resource: 'Order'}"
            @refetch-data="fetchItems"
            @saved="saved"
            @select="select"
          >
            <!-- name -->

            <template #[`item.user.name`]="{item}">
              <div class="d-flex align-center">
                <v-avatar
                  v-if="item.user"
                  :color="item.user.avatar ? '' : 'primary'"
                  :class="item.user.avatar ? '' : 'v-avatar-light-bg primary--text'"
                  size="30"
                >
                  <v-img
                    v-if="item.user.avatar"
                    :lazy-src="require(`@/assets/images/avatars/2.png`)"
                    :src="item.user.avatar || require(`@/assets/images/avatars/2.png`)"
                  ></v-img>
                  <span
                    v-else
                    class="font-weight-medium"
                  >{{ avatarText(item.user.name) }}</span>
                </v-avatar>

                <div
                  v-if="item.user"
                  class="d-flex flex-column ms-3"
                >
                  <router-link
                    :to="{ name : 'user-view', params : { id : item.user_id } }"
                    class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
                  >
                    {{ item.user.name }}
                  </router-link>
                  <!--              <small>@{{ item.manuscriptname }}</small>-->
                </div>
              </div>
            </template>

            <!-- actions -->
            <template #[`item.products`]="{item}">
              <v-btn
                small
                depressed
                color="info"
                @click.stop="openProductsDialog(item)"
              >
                {{ $t("Products") }}({{ item.products.length }})
              </v-btn>
            </template>
            <template #[`item.actions`]="{item}">
              <v-menu
                bottom
                left
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    :disabled="isCompleted(item.publication)"
                    v-on="on"
                  >
                    <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <!--              <v-list-item
                :to="{name:'ride-view',params:{id:item.id}}"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                  <span>{{ $t('Details') }}</span>
                </v-list-item-title>
              </v-list-item>-->


<!--                  <i-form
                    v-if="$can('edit','Order')"
                    v-model="item"
                    :object="item"
                    :is-form-active="isFormActive"
                    :headers="filters.filter(ele => ele.fields )"
                    :show-button="false"
                    :config="{url: '/admin/orders', title: item.id ? $t('Update order'): $t('New order'),
           btnText: 'Add a order',
            successMessage: $t('Order successfully saved.'),
            successMessage: $t('Error saving order.'),
          }"
                    @cancel="isFormActive = false"
                    @saved="fetchItems"
                  >
                  </i-form>-->

                  <v-list-item
                    v-if="$can('delete','Order') && item.status <= 0"
                    @click="confirmDelete(item)"
                  >
                    <v-list-item-title>
                      <v-icon
                        size="20"
                        class="me-2"
                      >
                        {{ icons.mdiDeleteOutline }}
                      </v-icon>
                      <span><span>{{ $t('Delete') }}</span></span>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </Row>
        </template>
      </v-data-table>
      <v-card-text class="pt-2">
        <v-row>
          <v-col
            lg="2"
            cols="3"
          >
            <rows-per-page :options="options" />
          </v-col>

          <v-col
            lg="10"
            cols="9"
            class="d-flex justify-end"
          >
            <v-pagination
              v-model="page"
              total-visible="6"
              :length="pageCount"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="deleteDialog"
      touchless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.smAndUp ? 300 : '100%'"
    >
      <v-card>
        <v-card-title>{{ $t("Confirm delete") }}</v-card-title>
        <v-divider />
        <v-card-text>{{ $t("Are you sure to delete?") }}</v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            small
            outlined
            @click="deleteDialog = false"
          >
            {{ $t("No") }}
          </v-btn>
          <v-spacer />
          <v-btn
            small
            color="primary"
            :loading="loading"
            @click="deleteItem"
          >
            {{ $t("Yes") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="isSnackbarBottomVisible"
      :color="$vuetify.theme.currentTheme.error"
      :light="$vuetify.theme.dark"
    >
      {{ snackBarMessage }}
    </v-snackbar>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiSquareEditOutline,
  mdiDotsVertical,
  mdiPlus,
  mdiClose,
  mdiMicrosoftExcel,
  mdiFileDocumentOutline,
  mdiDeleteOutline,
  mdiExportVariant,
  mdiAccountOutline,
  mdiPencilOutline,
  mdiCheckCircle,
  mdiCheckOutline,
  mdiCalendar,
} from '@mdi/js'
import { onUnmounted, ref, watch } from '@vue/composition-api'

// sidebar
import { avatarText, formatDate } from '@core/utils/filter'
import axios from '@axios'
import store from '@/store'
import itemStoreModule from './itemStoreModule'

import controller from './Controller'
import RowsPerPage from '@/components/RowsPerPage'

import HeaderFilters from '@/components/HeaderFilters'
import ExportExcel from '@/components/ExportExcel'

import Row from '@/components/Row'
import IForm from '@/components/IForm'
import Products from '@/views/apps/orders/Products'

export default {
  components: {
    Products,
    IForm,
    Row,
    ExportExcel,
    HeaderFilters,
    RowsPerPage,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-order'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, itemStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const {
      saved,
      item,
      items,
      validateOrder,
      downloadDialog,
      downloadObject,
      signatureDialog,
      accordDialog,
      tableColumns,
      filters,
      searchQuery,
      payFilter,
      sourceFilter,
      statusFilter,
      totalItems,
      loading,
      options,
      rideTotalLocal,
      selectedRows,
      select,
      isFormActive,
      isProductsFormActive,
      isPropositionActive,
      snackBarMessage,
      isSnackbarBottomVisible,
      isRelanceActive,
      openRelanceDialog,
      openDialog,
      openProductsDialog,
      openPropositionDialog,
      openSignatureDialog,
      openAccordDialog,
      deleteItem,
      deleteDialog,
      confirmDelete,
      fetchItems,
      resolveRideRoleVariant,
      resolveRideRoleIcon,
      resolveRideStatusVariant,
      resolveRideStatusName,
      resolveRideTotalIcon,
    } = controller()

    const superiors = ref([])

    const payOptions = [
      { title: 'Admin', value: 'admin' },
      { title: 'Order', value: 'order' },
      { title: 'Editor', value: 'editor' },
      { title: 'Maintainer', value: 'maintainer' },
      { title: 'Subscriber', value: 'subscriber' },
    ]

    const planOptions = [
      { title: 'Basic', value: 'basic' },
      { title: 'Company', value: 'company' },
      { title: 'Enterprise', value: 'enterprise' },
      { title: 'Standard', value: 'standard' },
    ]

    const statusOptions = [
      { title: 'Active', value: 1 },
      { title: 'Inactive', value: 0 },
    ]

    /* store
      .dispatch('app-ride/fetchCountries', { per_page: 500 }) */
    /* store
      .dispatch('app-ride/fetchSources', { per_page: 500 }) */
    /* store
      .dispatch('app/fetchGenres', { per_page: 500 }) */
    /* store
      .dispatch('app-ride/fetchFormats', { per_page: 500, type: 'ride' }) */
    /* store
      .dispatch('app-ride/fetchStatuses', { per_page: 500, status_type: 'ride' }) */

    axios
      .get('/countries', { params: { per_page: 500, type: 'order' } })
      .then(response => {
        store.state['app-order/countries'] = response.data.data
        const indexH = filters.value.findIndex(ele => ele.value === 'country.full_name')
        if (indexH > 0) {
          filters.value[indexH].filter.values = response.data.data
        }
      }).catch(error => console.log(error))

    /* axios
      .get('/statuses', { params: { per_page: 500, type: 'ride' } })
      .then(response => {
        store.state['app-ride/statuses'] = response.data.data
        const indexH = filters.value.findIndex(ele => ele.value === 'status')
        if (indexH > 0) {
          filters.value[indexH].filter.values = response.data.data
        }
      }).catch(error => console.log(error)) */
    axios
      .get('/sources', { params: { per_page: 500, type: 'order' } })
      .then(response => {
        store.state['app/sources'] = response.data.data
        const indexH = filters.value.findIndex(ele => ele.value === 'source.source_name')
        if (indexH > 0) {
          filters.value[indexH].filter.values = response.data.data
        }
        const indexO = filters.value.findIndex(ele => ele.value === 'origin.source_name')
        if (indexO > 0) {
          filters.value[indexO].filter.values = response.data.data
        }
      }).catch(error => console.log(error))
    axios
      .get('/formats', { params: { per_page: 500, type: 'order' } })
      .then(response => {
        store.state['app-order/formats'] = response.data.data
        const indexH = filters.value.findIndex(ele => ele.value === 'format.name')
        if (indexH > 0) {
          filters.value[indexH].filter.values = response.data.data
        }
      })
      .catch(error => console.log(error))
    axios.get('/genres', { params: { per_page: 500, type: 'order' } })
      .then(response => {
        store.state['app/genres'] = response.data.data
        const indexH = filters.value.findIndex(ele => ele.value === 'genre.label')
        if (indexH > 0) {
          filters.value[indexH].filter.values = response.data.data
        }
      })
      .catch(error => console.log(error))
    axios.get('/divisions', { params: { per_page: 500, type: 'order' } })
      .then(response => {
        store.state['app/divisions'] = response.data.data
        store.state.app.divisions = response.data.data
        const indexH = filters.value.findIndex(ele => ele.value === 'division.name')
        if (indexH > 0) {
          filters.value[indexH].filter.values = response.data.data
        }
        const indexH1 = filters.value.findIndex(ele => ele.value === 'drop_division.name')
        if (indexH1 > 0) {
          filters.value[indexH1].filter.values = response.data.data
        }
      })
      .catch(error => console.log(error))
    axios.get('/quarters', { params: { per_page: 1000, type: 'order' } })
      .then(response => {
        store.state['app/quarters'] = response.data.data
        store.state.app.quarters = response.data.data
        const indexH = filters.value.findIndex(ele => ele.value === 'quarter.name')
        if (indexH > 0) {
          filters.value[indexH].filter.values = response.data.data
        }
        const indexH1 = filters.value.findIndex(ele => ele.value === 'drop_quarter.name')
        if (indexH1 > 0) {
          filters.value[indexH1].filter.values = response.data.data
        }
      })
      .catch(error => console.log(error))

    filters.value = tableColumns

    const windowHeight = ref(window.innerHeight)
    const tableHeight = ref(window.innerHeight - 250)
    window.addEventListener('resize', () => {
      windowHeight.value = window.innerHeight
    })
    watch(windowHeight, () => {
      tableHeight.value = windowHeight.value - 250
    })

    return {
      tableHeight,
      saved,
      item,
      items,
      validateOrder,
      downloadDialog,
      downloadObject,
      superiors,
      filters,
      tableColumns,
      searchQuery,
      payFilter,
      sourceFilter,
      statusFilter,
      totalItems,
      payOptions,
      planOptions,
      statusOptions,
      loading,
      options,
      accordDialog,
      signatureDialog,
      rideTotalLocal,
      isPropositionActive,
      isFormActive,
      isProductsFormActive,
      selectedRows,
      select,
      snackBarMessage,
      isSnackbarBottomVisible,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      openDialog,
      isRelanceActive,
      openRelanceDialog,
      openPropositionDialog,
      openSignatureDialog,
      openProductsDialog,
      openAccordDialog,
      deleteItem,
      deleteDialog,
      confirmDelete,
      avatarText,
      formatDate,
      resolveRideRoleVariant,
      resolveRideRoleIcon,
      resolveRideStatusVariant,
      resolveRideStatusName,
      resolveRideTotalIcon,
      fetchItems,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiMicrosoftExcel,
        mdiPlus,
        mdiClose,
        mdiExportVariant,
        mdiAccountOutline,
        mdiPencilOutline,
        mdiCheckCircle,
        mdiCheckOutline,
        mdiCalendar,
      },
    }
  },
}
</script>
<style lang="scss">
  /*table {

    background-color: white;
    margin: auto;
    border-collapse: separate;
    border-spacing: 0;
    display: block;
    overflow-x: scroll;
  }
  .v-data-table td, th {
    margin: 0;
    border: 1px solid grey;
    white-space: nowrap;
    border-top-width: 0px;
  }

  .v-data-table thead tr:first-child
  }
  .fixed-column {
    position: absolute;
    width: 5em;
    left: 0;
    top: auto;
    border-top-width: 1px;
    margin-top: -1px;
  }*/
</style>
<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
